
import styled from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import MEDIA from 'constants/media'

export const VidWrap = styled.div`
  position: relative;
  overflow: hidden;
`

export const VidContainer = styled.div`
  text-align: center;
  video {
    /* Make video to at least 100% wide and tall */
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: 100%;
    height: auto;
  }
  ${MEDIA.medium`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-top: 140px;
    video {
      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      min-width: 100%;
      width: auto;
    }
  `}
`

export const VidTitle = styled.h1`
  position: absolute;
  color: ${COLORS.BLUE};
  font-size: 40px;
  font-weight: ${FONTS.WEIGHTDEMI};
  max-width: 1000px;
  padding: 0 20px;
  z-index: 2;
  width: 100%;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -10%);
  ${MEDIA.medium`
    position: relative;
    font-size: 60px;
    margin: auto;
    transform: translate(0,0);
    top: 0;
    left: 0;
  `}
  ${MEDIA.ie11`
    margin: 0 auto 60px;
  `}
`

export const VidDesktop = styled.div``

export const ImgForMobile = styled.div`
  img {
    width: 100%;
  }
`
