import {
  useState,
  useEffect,
} from 'react'

export interface WindowSize {
  width?: number,
  height?: number,
}

// Hook
export default (): WindowSize => {
  const isClient = typeof window === 'object'

  function getSize(): WindowSize {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [
    windowSize,
    setWindowSize,
  ] = useState(getSize())

  useEffect(() => {
    if (!isClient) {
      return (): void => undefined
    }

    function handleResize(): void {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return (): void => window.removeEventListener('resize', handleResize)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return windowSize
}
