import React, {
  useEffect,
  useState,
} from 'react'

import useWindowSize from 'hooks/useWindowSize'

import { sizes } from 'constants/media'

import {
  ContainerProps,
  Video as VideoType,
} from './types'

import Video from './index.presentational'

const supportedMimeTypes = [
  'video/webm',
  'video/mp4',
]

const mimeTypeRegexp = /^.*\.([^.]*)$/

const VideoContainer: React.FC<ContainerProps> = ({
  video,
  ...props
}): React.ReactElement => {
  const windowSize = useWindowSize()

  const [
    isWindowSizeMobile,
    setIsWindowSizeMobile,
  ] = useState<boolean|undefined>(undefined)

  useEffect(() => {
    setIsWindowSizeMobile(!windowSize.width || windowSize.width < sizes.xsmall)
  }, [ windowSize ])

  // Assign mime-types to all videos, and ensure that WebM videos always come before MP4 videos
  const videos = (video as VideoType[])
    .reduce((acc: VideoType[], video: string|VideoType) => {
      let mimeType: string|undefined
      let src: string
      if (typeof video === 'string') {
        src = video
        mimeType = `video/${src.replace(mimeTypeRegexp, '$1').toLowerCase()}`
        mimeType = (supportedMimeTypes.includes(mimeType) && mimeType) || undefined
      }
      else {
        src = video.src
        mimeType = (video.mimeType && supportedMimeTypes.includes(video.mimeType) && video.mimeType) ||
          (supportedMimeTypes.includes(src.replace(mimeTypeRegexp, '$1').toLowerCase()) && src.replace(mimeTypeRegexp, '$1').toLowerCase()) ||
          undefined
      }

      if (mimeType) {
        acc.push({
          mimeType: mimeType as string,
          src,
        })
      }

      return acc
    }, [] as VideoType[])
    .sort((a: VideoType, b: VideoType) => {
      return b.mimeType === 'video/webm' && a.mimeType !== 'video/webm' ? 1 : -1
    })

  return React.createElement(
    Video,
    {
      isWindowSizeMobile,
      videos: videos as VideoType[],
      ...props,
    },
    props.children,
  )
}

export default VideoContainer
