import React from 'react'

import { Props } from './types'

import StyledImg from './styles'

const Img: React.FC<Props> = ({
  src,
  src2x = undefined,
  src3x = undefined,
  srcSet = undefined,
  ...props
}): React.ReactElement => (
  <StyledImg
    {...props}
    src={src}
    srcSet={srcSet || `${src} 1x${src2x ? `, ${src2x} 2x` : ''}${src3x ? `, ${src3x} 3x` : ''}`}
  />
)

export * from './types'

export default Img
