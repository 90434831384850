import React from 'react'

import Img from 'components/base/Img'

import { Props } from './types'

import {
  VidWrap,
  VidContainer,
  VidDesktop,
  ImgForMobile,
} from './styles'

const PageHeroVideo: React.FC<Props> = ({
  children,
  imgMobile = undefined,
  isWindowSizeMobile = undefined,
  videos,
  videoPoster = undefined,
}): React.ReactElement => (
  <VidWrap>
    <VidContainer>
      {(imgMobile && isWindowSizeMobile && (
        <ImgForMobile>
          <Img src={imgMobile} src2x={imgMobile} alt='Mobile Hero' />
        </ImgForMobile>
      )) || null}
      {(isWindowSizeMobile === false && (
        <VidDesktop>
          <video autoPlay={true} loop={true} muted={true} playsInline={true} poster={videoPoster || ''}>
            {videos.map(video => <source key={video.src} src={video.src} type={video.mimeType} />)}
            Your browser does not support HTML5 video.
          </video>
        </VidDesktop>
      )) || null}
      {children}
    </VidContainer>
  </VidWrap>
)

export default PageHeroVideo
